<template>
  <button
    class="wet-button wet-button-outlined empty-ph"
    style="min-width:10rem;"
    :class="{
      'disabled': downloadAllDisabled,
      'loading': downloading,
    }"
    @click="clickBtn"
  >
    <slot></slot>
  </button>
</template>
<script>
import { mapState } from 'vuex';
import { saveAs } from 'file-saver';
import common from '@/mixins/common';

export default {
  props: {
    attempts: { /** in seconds, integer */
      type: Number,
      default: 1,
    },
    url: String,
    errorMessage: String,
    lazy: { /** lazy check */
      type: Boolean,
      default: false,
    },
  },
  mixins: [common],
  data() {
    return {
      downloadAllDisabled: !this.lazy,
      downloading: false,
      filename: 'policy_user_id.zip',
    };
  },
  computed: {
    ...mapState(['strings']),
  },
  mounted() {
    if (!this.lazy) this.startCheck();
  },
  methods: {
    clickBtn() {
      if (this.lazy) {
        this.startCheck()
          .then(() => {
            this.saveFile();
            this.downloadAllDisabled = false;
          });
      } else {
        this.saveFile();
      }
    },
    async startCheck() {
      // add interval for check download all link
      this.downloadAllDisabled = true;
      this.downloading = true;
      let wait = false; /** flag for prevent setInterval with async/await collision */
      let limitSecond = this.attempts; /** max time period for check link */
      const promisifyInterval = (resolve) => {
        const checkIntervalId = setInterval(async () => {
          if (!wait) {
            wait = true;
            const available = limitSecond > 0 && await this.checkLink(this.url); /** check link */
            if (available || limitSecond <= 0) {
              clearInterval(checkIntervalId); /** clear interval */
              try {
                await this.downloadAll(); /** download file */
                this.downloadAllDisabled = false; /** allow save file */
              } catch (error) {
                // show the popup error
                const message = this.errorMessage;
                const modal = {
                  id: 'wet-invalid-download-modal',
                  content: `<div class="text-center">${message}</div>`,
                  actions: [
                    {
                      name: 'wet-invalid-download-modal-ok',
                      title: this.strings.commonApp?.ok,
                      event: 'close',
                    },
                  ],
                };
                this.$store.dispatch('addModal', modal);
              }
              this.downloading = false;
              resolve();
            }
            limitSecond -= 1;
            wait = false;
          }
        }, 1000);
        this.$once('hook:destroyed', () => {
          clearInterval(checkIntervalId);
        });
      };
      await new Promise((resolve) => {
        promisifyInterval(resolve);
      });
    },
    async checkLink(url) {
      /** check link, if any error - return false */
      let status = false;
      try {
        const res = await this.callApi({ url, method: 'head', loading: false });
        status = res.status === 200;
      } catch (error) {
        //
      }
      return status;
    },
    async downloadAll() {
      /** method for download all files */
      const res = await this.callApi({
        url: this.url,
        responseType: 'blob',
      }, false);
      const contentDisposition = Object.fromEntries(res.headers['content-disposition'].split(';').map((el) => el.trim().split('=')));
      const r = /(^"|"$)/g;
      this.filename = contentDisposition.filename?.replace(r, '');
      this.blobFile = res.data;
    },
    saveFile() {
      /** only for save file */
      this.$emit('save', this.downloadAllDisabled);
      if (!this.downloadAllDisabled) {
        const name = this.filename;
        saveAs(this.blobFile, name);
      }
    },
  },
};
</script>
